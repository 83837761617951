import React from "react"
import { Grommet, Box, Button, Text, Menu } from "grommet"
import { grommet } from "grommet/themes"
import "../pages/index.css"
import "./i18n"
import { navigateTo } from "gatsby-link"
import { Adsense } from "@ctrl/react-adsense"

const Home = ({ location, children, max = "560px" }) => {
  return (
    <Grommet full theme={grommet}>
      <Box
        gridArea="header"
        direction="row"
        align="center"
        pad={{ horizontal: "medium", vertical: "small" }}
        background="brand"
      >
        <Button
          margin={{ right: "20px" }}
          onClick={() => {
            navigateTo("/")
          }}
        >
          <Text size="large">OpenFengShui</Text>
        </Button>
        <Menu
          label="Tools"
          items={[
            {
              label: "QM Hour",
              onClick: () => {
                navigateTo("/qmdj/hour")
              },
            },
            {
              label: "QM Month/Day",
              onClick: () => {
                navigateTo("/qmdj/month")
              },
            },
            {
              label: "QiMen Finder",
              onClick: () => {
                navigateTo("/qmdj/finder")
              },
            },
            {
              label: "Flying Star",
              onClick: () => {
                navigateTo("/flyingstar/chart")
              },
            },
            {
              label: "8 Mansion",
              onClick: () => {
                navigateTo("/bazhai/chart")
              },
            },
            {
              label: "Calendar",
              onClick: () => {
                navigateTo("/calendar/page")
              },
            },
            {
              label: "Bazi",
              onClick: () => {
                navigateTo("/bazi/chart")
              },
            },
            {
              label: "Plum Blossom Divination",
              onClick: () => {
                navigateTo("/iching/")
              },
            },
          ]}
        />

        <Menu
          label="Learn"
          items={[
            {
              label: "Videos",
              onClick: () => {
                navigateTo("/videos/")
              },
            },
          ]}
        />
      </Box>
      <Box align="center" justify="center" pad="small">
        <Box
          pad={{
            bottom: "64px",
          }}
          fill="horizontal"
          width={{
            min: "360px",
            max,
          }}
        >
          {children}
        </Box>
        <Adsense client="ca-pub-6343289823091285" slot="5114483936" />
      </Box>
    </Grommet>
  )
}

export default Home
